import React, {FunctionComponent, ReactElement} from "react";
import {Route, Routes} from "react-router-dom";
import Events2023 from "./Events2023";
import {EvelinWaldmann} from "./Artists/2023/EvelinWaldmann";
import {JensPuppe} from "./Artists/2023/JensPuppe";
import {FranziskaGussmann} from "./Artists/2023/FranziskaGussmann";
import {UweSchaffranietz} from "./Artists/2023/UweSchaffranietz";


const EventList: FunctionComponent = (): ReactElement => {
  return (
    <>
      <Routes>
        <Route path={"evelin"} element={<EvelinWaldmann/>}/>
        <Route path={"jens"} element={<JensPuppe/>}/>
        <Route path={"franziska"} element={<FranziskaGussmann/>}/>
        <Route path={"uwe"} element={<UweSchaffranietz/>}/>
        <Route path={"*"} element={<Events2023/>}/>
      </Routes>
    </>
  );
}
export default EventList;
