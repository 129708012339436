import React, {FunctionComponent, ReactElement} from "react";
import {Route, Routes} from "react-router-dom";
import ArtistStephanieMai from "./Artists/2022/ArtistStephanieMai";
import ArtistLuiseWalter from "./Artists/2022/ArtistLuiseWalter";
import ArtistSteffenMertens from "./Artists/2022/SteffenMertens";
import {Archive} from "./Archive";

export const ArchiveList: FunctionComponent = (): ReactElement => {
  return (
      <>
        <Routes>
          <Route path="/stephanie/*" element={<ArtistStephanieMai/>}/>
          <Route path="/luise/*" element={<ArtistLuiseWalter/>}/>
          <Route path="/steffen/*" element={<ArtistSteffenMertens/>}/>
          <Route path={"*"} element={<Archive/>}/>
        </Routes>
      </>
  )
}