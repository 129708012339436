import {FunctionComponent, ReactElement} from "react";
import 'react-photoswipe/lib/photoswipe.css';
import {UweSchaffranietz} from "./Artists/2023/UweSchaffranietz";


const Event: FunctionComponent = (): ReactElement => {
  return (
    <UweSchaffranietz/>
  );
}
export default Event;
