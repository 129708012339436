import {Col, Container, Image, Row} from "react-bootstrap";
import {PhotoSwipeGallery} from "react-photoswipe";
import {AppUrl} from "../../../../types/AppUrl";

export const UweSchaffranietz = () => {

  let items: any[] = [
    {
      src: AppUrl.getBaseUrl() + '/img/2023/uwe_schaffranietz/Ausstellung-Kleine-Galerie-Elsterwerda01.jpg',
      thumbnail: AppUrl.getBaseUrl() + '/img/2023/uwe_schaffranietz/thumbnail/Ausstellung-Kleine-Galerie-Elsterwerda01.jpg',
      w: 1204,
      h: 1813,
      title: '<div class="imageCaption fadeIn"><div>Foto: © Uwe Schaffranietz</div>'
    },
    {
      src: AppUrl.getBaseUrl() + '/img/2023/uwe_schaffranietz/Ausstellung-Kleine-Galerie-Elsterwerda02.jpg',
      thumbnail: AppUrl.getBaseUrl() + '/img/2023/uwe_schaffranietz/thumbnail/Ausstellung-Kleine-Galerie-Elsterwerda02.jpg',
      w: 1232,
      h: 1877,
      title: '<div class="imageCaption fadeIn"><div>Foto: © Uwe Schaffranietz</div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2023/uwe_schaffranietz/Ausstellung-Kleine-Galerie-Elsterwerda03.jpg',
      thumbnail: AppUrl.getBaseUrl() + '/img/2023/uwe_schaffranietz/thumbnail/Ausstellung-Kleine-Galerie-Elsterwerda03.jpg',
      w: 1239,
      h: 1859,
      title: '<div class="imageCaption fadeIn"><div>Foto: © Uwe Schaffranietz</div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2023/uwe_schaffranietz/Ausstellung-Kleine-Galerie-Elsterwerda04.jpg',
      thumbnail: AppUrl.getBaseUrl() + '/img/2023/uwe_schaffranietz/thumbnail/Ausstellung-Kleine-Galerie-Elsterwerda04.jpg',
      w: 1487,
      h: 1933,
      title: '<div class="imageCaption fadeIn"><div>Foto: © Uwe Schaffranietz</div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2023/uwe_schaffranietz/Ausstellung-Kleine-Galerie-Elsterwerda05.jpg',
      thumbnail: AppUrl.getBaseUrl() + '/img/2023/uwe_schaffranietz/thumbnail/Ausstellung-Kleine-Galerie-Elsterwerda05.jpg',
      w: 2321,
      h: 1964,
      title: '<div class="imageCaption fadeIn"><div>Foto: © Uwe Schaffranietz</div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2023/uwe_schaffranietz/Ausstellung-Kleine-Galerie-Elsterwerda06.jpg',
      thumbnail: AppUrl.getBaseUrl() + '/img/2023/uwe_schaffranietz/thumbnail/Ausstellung-Kleine-Galerie-Elsterwerda06.jpg',
      w: 1221,
      h: 1820,
      title: '<div class="imageCaption fadeIn"><div>Foto: © Uwe Schaffranietz</div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2023/uwe_schaffranietz/Ausstellung-Kleine-Galerie-Elsterwerda07.jpg',
      thumbnail: AppUrl.getBaseUrl() + '/img/2023/uwe_schaffranietz/thumbnail/Ausstellung-Kleine-Galerie-Elsterwerda07.jpg',
      w: 2329,
      h: 1970,
      title: '<div class="imageCaption fadeIn"><div>Foto: © Uwe Schaffranietz</div>'
    }
  ];

  let options = {
    history: false,
    focus: false,
    showAnimationDuration: 600,
    hideAnimationDuration: 600,
    closeOnScroll: false,
    shareEl: false,
    fullscreenEl: false,
    addCaptionHTMLFn: function (item: any, captionEl: any, isFake: boolean) {
      // item      - slide object
      // captionEl - caption DOM element
      // isFake    - true when content is added to fake caption container
      //             (used to get size of next or previous caption)

      if (!item.title) {
        captionEl.children[0].innerHTML = '';
        return false;
      }
      captionEl.children[0].innerHTML = item.title;
      return true;
    }
  };

  const getThumbnailContent = (item: any) => {
    return (
        <img src={item.thumbnail} alt={item.title} style={{margin: '3px'}}/>
    );
  }

  return (
      <div className={'siteContainer'}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={'eventPeriod'}>ab 09.09.2023, Galeriegespräch am 22.09.2023 um 19:00 Uhr</div>
              <div className={'eventPeriod'}>Zeichnungen Malerei Design</div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Image src={AppUrl.getBaseUrl() + '/img/2023/uwe_schaffranietz/UweSchaffranietz.jpg'}
                     className={'artistImage '}
                     alt={'Uwe schaffranietz'}
              />
              <div className={'artistImageText'}>© Uwe Schaffranietz</div>
            </Col>
            <Col md={8}>
              <div className={'artistName'}>Uwe Schaffranietz</div>
              <div className={'artistBio'}>
                <ul>
                  <li>Jahrgang 63</li>
                  <li>Technische Ausbildung und Arbeit in der Industrie</li>
                  <li>Abendstudium an der Kunsthochschule Dresden (Malerei/Grafik)</li>
                  <li>1986 - 91 Direktstudium an der Hochschule für Kunst und Design
                    "Burg Giebichenstein" in Halle (Diplom Industrialdesign)
                  </li>
                  <li>1992 Gründung des Unternehmens SCHAFFRANIETZ DESIGN</li>
                </ul>
                <br/>
                <i>
                  Die Verknüpfung von kreativen, künstlerischen und technisch -
                  konstruktiven Leistungen sowie eine langjährige praktische
                  Projekterfahrung in unterschiedlichen Branchen und Bereichen als
                  Basis.
                </i>
                <br/>
                <br/>
                <br/>
                 <b>Leistungsportfolio:</b>
                <ul>
                  <li>Beratung zu neuen Möglichkeiten (der Blick von außen)</li>
                  <li>Produktentwicklung</li>
                  <li>Produktdesign</li>
                  <li>Design von Kommunikationslösungen (Markenentwicklung)</li>
                  <li>Grafik / Illustration / Modelle zur Erklärung bzw. Präsentation</li>
                  <li>Messe- und Ausstellungsdesign</li>
                  <li>Konzeption und Gestaltung von Innen- und Außenräumen</li>
                  <li>Kunst- und Designobjekte</li>
                </ul>
                <br/>
                <b> Designpreis "reddot"</b><br/>
                <b>div. Medien- und Fernsehpreise</b><br/>
                <b>Vertreten im Designmuseum "Neue Sammlung" München ...</b><br/>
                <br/>
                Produktpräsentationen erfolgten auf internationalen Fachmessen und
                künstlerische Präsentationen in Form von Einzelausstellungen
                zumeist im deutschsprachigen Raum.
                <br/><br/>
                Künstlerische Arbeiten:<br/>
                Uwe Schaffranietz beschäftigt sich mit unterschiedlichen künstlerischen Techniken in
                einer zweidimensionalen und gegenständlichen Ausdrucksweise
              </div>
              <div className={"additionalInfos"}>Kontakt:<br/>
                Uwe Schaffranietz<br/>
                (schaffranietz design)<br/>
                Waldstr. 28, 04924 Bad Liebenwerda - OT Maasdorf<br/>
                Tel.: (+49) 035341 12467<br/>
                info(at)schaffranietz-design.de<br/>
                Webseite: <a href={"https://schaffranietz-design.com/"} className={'externalLink'}>schaffranietz-design.de</a>
            </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <PhotoSwipeGallery items={items} options={options} thumbnailContent={getThumbnailContent}/>
            </Col>
          </Row>
        </Container>
      </div>
  )
}
