import {Col, Container, Image, Row} from "react-bootstrap";
import {PhotoSwipeGallery} from "react-photoswipe";
import {AppUrl} from "../../../../types/AppUrl";

export const FranziskaGussmann = () => {

  let items: any[] = [
    {
      src: AppUrl.getBaseUrl() + '/img/2023/franziska_gussmann/1.jpg', thumbnail: AppUrl.getBaseUrl() + '/img/2023/franziska_gussmann/thumbnail/1.jpg',
      w: 834, h: 1250,
      title: '<div class="imageCaption fadeIn"><div>Foto: © Ronka Oberhammer</div>'
    },
    {
      src: AppUrl.getBaseUrl() + '/img/2023/franziska_gussmann/2.jpg', thumbnail: AppUrl.getBaseUrl() + '/img/2023/franziska_gussmann/thumbnail/2.jpg',
      w: 834, h: 1250,
      title: '<div class="imageCaption fadeIn"><div>Foto: © Ronka Oberhammer</div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2023/franziska_gussmann/3.jpg', thumbnail: AppUrl.getBaseUrl() + '/img/2023/franziska_gussmann/thumbnail/3.jpg',
      w: 834, h: 1250,
      title: '<div class="imageCaption fadeIn"><div>Foto: © Ronka Oberhammer</div>'
    },{
      src: AppUrl.getBaseUrl()+'/img/2023/franziska_gussmann/4.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2023/franziska_gussmann/thumbnail/4.jpg',
      w: 758, h: 1250,
      title: '<div class="imageCaption fadeIn"><div>Foto: © Ronka Oberhammer</div>'
    },{
      src: AppUrl.getBaseUrl()+'/img/2023/franziska_gussmann/5.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2023/franziska_gussmann/thumbnail/5.jpg',
      w: 834, h: 1250,
      title: '<div class="imageCaption fadeIn"><div>Foto: © Ronka Oberhammer</div>'
    },{
      src: AppUrl.getBaseUrl()+'/img/2023/franziska_gussmann/6.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2023/franziska_gussmann/thumbnail/6.jpg',
      w: 833, h: 1250,
      title: '<div class="imageCaption fadeIn"><div>Foto: © Ronka Oberhammer</div>'
    }
  ];

  let options = {
    history: false,
    focus: false,
    showAnimationDuration: 600,
    hideAnimationDuration: 600,
    closeOnScroll: false,
    shareEl: false,
    fullscreenEl: false,
    addCaptionHTMLFn: function (item: any, captionEl: any, isFake: boolean) {
      // item      - slide object
      // captionEl - caption DOM element
      // isFake    - true when content is added to fake caption container
      //             (used to get size of next or previous caption)

      if (!item.title) {
        captionEl.children[0].innerHTML = '';
        return false;
      }
      captionEl.children[0].innerHTML = item.title;
      return true;
    }
  };

  const getThumbnailContent = (item: any) => {
    return (
      <img src={item.thumbnail} alt={item.title} style={{margin: '3px'}}/>
    );
  }

  return (
    <div className={'siteContainer'}>
      <Container>
        <Row>
          <Col md={12}>
            <div className={'eventPeriod'}>08.07.-07.09.2023, Galeriegespräch am 04.08.2023 um 19:00 Uhr</div>
            <div className={'eventPeriod'}>SKULPTUREN</div>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Image src={AppUrl.getBaseUrl() + '/img/2023/franziska_gussmann/FranziskaGussmann.jpg'}
                   className={'artistImage '}
                   alt={'Franziska Gußmann'}
            />
            <div className={'artistImageText'}>© Dieter Düvelmeyer</div>
          </Col>
          <Col md={8}>
            <div className={'artistName'}>Franziska Gußmann</div>
            <div className={'artistBio'}>
              lebt und arbeitet im Berlin/Brandenburg<br/>
              „Die Sichtweite geschlossener Augen ist grenzenlos.“
              <br/><div className={'artistImageText'}  style={{float: 'left'}}>Raymond Walden</div>
              <br/>
              Meine künstlerischen Arbeiten sind lebensgroße und grob gearbeitete menschliche Figuren aus Holz. Die Körperhaltung
              ist meist unbewegt und ruhend. In den fein ausgearbeiteten Gesichtern stelle ich die Augen stets geschlossen dar.
              Mit Holz zu arbeiten, bedeutet für mich, mit eigenwilligen Kräften zu kooperieren. In einem körperlich herausfordernden
              Prozess bearbeite ich das Material. Dabei schäle ich Stück für Stück den Körper aus dem rohen Stamm. Ich säge, ich
              schleife, ich schnitze und ich kratze. Die Oberflächenstruktur unterstütze ich durch farbige Akzente und blockhafte
              Farbflächen. Manchmal hat die Maserung des Holzes zu viel Feuer, dann muss ich mit Farbe löschen. Manchmal feuere
              ich mit Farbe erst an.<br/>
              Mein Interesse gilt dem verborgenen inneren Raum hinter den geschlossenen Augen. Er symbolisiert für mich den
              intimsten Freiraum eines jeden Menschen. Er ist weder einsehbar noch kontrollierbar.
              <br/><br/>
              seit 2011 freischaffende Bildhauerin mit Atelier in Oehna - Berlin/Brandenburg<br/>
              2008-2016 Studium Bachelor of Arts (B.A.) Soziale Arbeit, ASH Berlin<br/>
              2004-2008 Ausbildung zur Holzbildhauerin bei den Meistern K.P. Gust und I.Titov<br/>
              2000-2002 Bildhauerisches Gestalten bei Karl Späth<br/>
              1984 in Luckenwalde geboren<br/>
              <br/>
              Ausstellungen<br/><br/>
              2023 Farbräume I Neue Galerie, Wünsdorf-Waldstadt<br/>
              2022 3 in Resonanz I klassMo- Kulturwerkstatt, Luckenwalde<br/>
              2021 3 in Resonanz I Offene Ateliers Land Brandenburg, Oehna<br/>
              2020 Open Spaces I Atelierhaus-Genossenschaft-Berlin, Haus 1, Berlin<br/>
              2020 Kein Tag wie jeder andere I Galerie Torfstraße, Berlin<br/>
              2019 Kunst im Mausoleum I Südwestkirchhof, Stahnsdorf<br/>
              2019 2 in Resonanz I Offene Ateliers Land Brandenburg, Oehna<br/>
              2019 4 souterrain I Atelier Doris Junker, Berlin<br/>
              2018 TKT I Biomalz-Fabrik, Teltow<br/>
              2018 Akzente 15 I Neue Galerie, Wünsdorf-Waldstadt<br/>
              2018 ArtKreuzberg I Berlin<br/>
              2017 40x40 I Rathaus Friedrichshagen, Berlin<br/>
              2016 4 im Herbst I Galerie KungerKiez, Berlin<br/>
              2016 BME I Stilwerk, Berlin<br/>
              2015 20 and 4 artists I Projektraum Bergmannstraße 5, Berlin<br/>
              2015 Akzente 12 I Neue Galerie, Wünsdorf-Waldstadt<br/>
              2015 DISPLACED 2015 I Mendelsohnhalle, Luckenwalde<br/>
              2014 Kunstkreuz I Atelier Doris Junker, Berlin<br/>
              2014 ArtKreuzberg I Berlin<br/>
              2014 JA I Galerie 10, Berlin<br/>
              2012 KunstStoffHolz I Jagdschloss Grunewald, Berlin<br/>
            </div>
            <div className={"additionalInfos"}>Im Internet:<br/>
              <a href={"http://www.franziskagussmann.de"} className={'externalLink'}>www.franziskagussmann.de</a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <PhotoSwipeGallery items={items} options={options} thumbnailContent={getThumbnailContent}/>
            <div className={'artistImageText'} style={{float: 'left'}}>© Ronka Oberhammer</div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
