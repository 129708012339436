import {Col, Container, Image, Row} from "react-bootstrap";
import {PhotoSwipeGallery} from "react-photoswipe";
import {AppUrl} from "../../../../types/AppUrl";

export const EvelinWaldmann = () => {

  let items: any[] = [
    {
      src: AppUrl.getBaseUrl() + '/img/2023/evelin_waldmann/1.jpg', thumbnail: AppUrl.getBaseUrl() + '/img/2023/evelin_waldmann/thumbnail/1.jpg',
      w: 1088, h: 1500,
      title: '<div class="imageCaption fadeIn"><div>Selbstporträt</div><div>Öl auf Hartfaser</div><div>60 x 80cm</div><div>1985</div></div>'
    },
    {
      src: AppUrl.getBaseUrl() + '/img/2023/evelin_waldmann/2.jpg', thumbnail: AppUrl.getBaseUrl() + '/img/2023/evelin_waldmann/thumbnail/2.jpg',
      w: 1500, h: 1024,
      title: '<div class="imageCaption fadeIn"><div>Brooklynbridge</div><div>Öl auf Leinwand</div><div>150 x 100cm</div><div>2016-2019</div></div>'
    }, {
      src: AppUrl.getBaseUrl() + '/img/2023/evelin_waldmann/4.jpg', thumbnail: AppUrl.getBaseUrl() + '/img/2023/evelin_waldmann/thumbnail/4.jpg',
      w: 1500, h: 736,
      title: '<div class="imageCaption fadeIn"><div>Nexdorf im Winterschlaf</div><div>Öl auf Leinwand</div><div>60 x 60cm</div><div>2009</div></div>'
    },{
      src: AppUrl.getBaseUrl()+'/img/2023/evelin_waldmann/5.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2023/evelin_waldmann/thumbnail/5.jpg',
      w: 1500, h: 1239,
      title: '<div class="imageCaption fadeIn"><div>Grube Louise, letzte Schicht</div><div>Aquarell auf Packpapier</div><div>60 x 60cm</div><div>1991</div></div>'
    },{
      src: AppUrl.getBaseUrl()+'/img/2023/evelin_waldmann/8.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2023/evelin_waldmann/thumbnail/8.jpg',
      w: 1124, h: 1500,
      title: '<div class="imageCaption fadeIn"><div>Sara mit Sonnenschirm</div><div>Öl auf Leinwand</div><div>40 x 40cm</div><div>2018</div></div>'
    },{
      src: AppUrl.getBaseUrl()+'/img/2023/evelin_waldmann/10.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2023/evelin_waldmann/thumbnail/10.jpg',
      w: 1500, h: 1053,
      title: '<div class="imageCaption fadeIn"><div>Schloss Finsterwalde</div><div>Öl auf Hartfaser</div><div>40 x 60cm</div><div>2011</div></div>'
    },{
      src: AppUrl.getBaseUrl()+'/img/2023/evelin_waldmann/11.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2023/evelin_waldmann/thumbnail/11.jpg',
      w: 1500, h: 900,
      title: '<div class="imageCaption fadeIn"><div>Historischer Stadtkern Uebigau im Schnee</div><div>Öl auf Hartfaser</div><div>40 x 70cm</div><div>2010</div></div>'
    },{
      src: AppUrl.getBaseUrl()+'/img/2023/evelin_waldmann/15.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2023/evelin_waldmann/thumbnail/15.jpg',
      w: 1500, h: 1053,
      title: '<div class="imageCaption fadeIn"><div>Kraniche</div><div>Öl auf Hartfaser</div><div>50 x 70cm</div><div>2010</div></div>'
    },{
      src: AppUrl.getBaseUrl()+'/img/2023/evelin_waldmann/16.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2023/evelin_waldmann/thumbnail/16.jpg',
      w: 1500, h: 633,
      title: '<div class="imageCaption fadeIn"><div>Triptychon Boote auf dem Darß</div><div>Öl auf Leinwand</div><div>46 x 108cm</div><div>2018</div></div>'
    },{
      src: AppUrl.getBaseUrl()+'/img/2023/evelin_waldmann/17.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2023/evelin_waldmann/thumbnail/17.jpg',
      w: 1500, h: 1050,
      title: '<div class="imageCaption fadeIn"><div>Blaues Pferd, Rumänien</div><div>Öl auf Hartfaser</div><div>60 x 53cm</div><div>2011</div></div>'
    },{
      src: AppUrl.getBaseUrl()+'/img/2023/evelin_waldmann/25.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2023/evelin_waldmann/thumbnail/25.jpg',
      w: 738, h: 1500,
      title: '<div class="imageCaption fadeIn"><div>Stille</div><div>Öl auf Leinwand</div><div>70 x 140cm</div><div>2023</div></div>'
    },{
      src: AppUrl.getBaseUrl()+'/img/2023/evelin_waldmann/27.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2023/evelin_waldmann/thumbnail/27.jpg',
      w: 995, h: 1500,
      title: '<div class="imageCaption fadeIn"><div>Selbstporträt, Abschlussarbeit HfBK Dresden</div><div></div><div></div><div>1985</div></div>'
    },{
      src: AppUrl.getBaseUrl()+'/img/2023/evelin_waldmann/28.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2023/evelin_waldmann/thumbnail/28.jpg',
      w: 1500, h: 745,
      title: '<div class="imageCaption fadeIn"><div>Rotationsspuren</div><div>Acryl auf Leinwand</div><div>70 x 140cm</div><div>2022</div></div>'
    },{
      src: AppUrl.getBaseUrl()+'/img/2023/evelin_waldmann/32.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2023/evelin_waldmann/thumbnail/32.jpg',
      w: 1500, h: 1051,
      title: '<div class="imageCaption fadeIn"><div>Liegender Akt</div><div>Gouache auf Packpapier</div><div>72 x 100cm</div><div>1985</div></div>'
    },{
      src: AppUrl.getBaseUrl()+'/img/2023/evelin_waldmann/34.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2023/evelin_waldmann/thumbnail/34.jpg',
      w: 1201, h: 1500,
      title: '<div class="imageCaption fadeIn"><div>Sitzende am Strand</div><div>Kohle auf Papier</div><div>30 x 40cm</div><div>2019</div></div>'
    },{
      src: AppUrl.getBaseUrl()+'/img/2023/evelin_waldmann/40.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2023/evelin_waldmann/thumbnail/40.jpg',
      w: 1125, h: 1500,
      title: '<div class="imageCaption fadeIn"><div>Blumen</div><div>Öl auf Leinwand</div><div>60 x 80cm</div><div>2018</div></div>'
    },{
      src: AppUrl.getBaseUrl()+'/img/2023/evelin_waldmann/44.jpg', thumbnail: AppUrl.getBaseUrl()+'/img/2023/evelin_waldmann/thumbnail/44.jpg',
      w: 1500, h: 1144,
      title: '<div class="imageCaption fadeIn"><div>Schottlandimpressionen in Glen Cona</div><div>Öl auf Leinwand</div><div>70 x 90cm</div><div>2022</div></div>'
    }
  ];

  let options = {
    history: false,
    focus: false,
    showAnimationDuration: 600,
    hideAnimationDuration: 600,
    closeOnScroll: false,
    shareEl: false,
    fullscreenEl: false,
    addCaptionHTMLFn: function (item: any, captionEl: any, isFake: boolean) {
      // item      - slide object
      // captionEl - caption DOM element
      // isFake    - true when content is added to fake caption container
      //             (used to get size of next or previous caption)

      if (!item.title) {
        captionEl.children[0].innerHTML = '';
        return false;
      }
      captionEl.children[0].innerHTML = item.title;
      return true;
    }
  };

  const getThumbnailContent = (item: any) => {
    return (
      <img src={item.thumbnail} alt={item.title} style={{margin: '3px'}}/>
    );
  }

  return (
    <div className={'siteContainer'}>
      <Container>
        <Row>
          <Col md={12}>
            <div className={'eventPeriod'}>11.02.-20.04.2023, Galeriegespräch am 26.02.2023 um 15:00 Uhr</div>
            <div className={'eventPeriod'}></div>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Image src={AppUrl.getBaseUrl() + '/img/2023/evelin_waldmann/EvelinWaldmann.jpg'}
                   className={'artistImage '}
                   alt={'Evelin Waldmann'}
            />
            <div className={'artistImageText'}>© Evelin Waldmann</div>
          </Col>
          <Col md={8}>
            <div className={'artistName'}>Evelin Waldmann</div>
            <div className={'artistBio'}>
              1960 geboren in Langennaundorf <br/>
              1974-1977 neben dem Abitur in Elsterwerda Abendstudium an der Kunsthochschule in Dresden<br/>
              1977 Besuch der VIII. Kunstausstellung der DDR zusammen mit Prof. Tiedeken und Prof. Willi Sitte<br/>
              1977 Eignungsprüfung an der Kunsthochschule Dresden und Annahme zum Studium,Fachrichtung Gemälderestaurierung<br/>
              1978-1984 Studium an der Kunsthochschule in Dresden, Abschluss: Gemälderestaurierung (Diplom)<br/>
              1986 Aufnahme in den Verband Bildender Künstler<br/>
              1989 Juli/August Übergang in die Selbständigkeit, Umzug nach LK Elbe Elster zusammen mit Lebenspartner Ralph Schirrwagen<br/>
              Ab November 1989 freischaffende Restauratorin,<br/>
              1990/1991 Klosterkirche Doberlug, Jugendstilvilla Falkenberg Elster<br/>
              1993 Schloss Elsterwerda (Restaurierung und Vergoldungsarbeiten in den Innenräumen), Schloss Martinskirchen, Kirche Großkmehlen, Restaurierung des Georgsaltares und vieles mehr.<br/>
              1996 Schloss Martinskirchen (Restaurierung der Deckenmalerei), Schloss und Kirche Finsterwalde (Innenräume untersuchen, in der Kirche Restaurierung der Kanzel und des Altares,<br/>
              Schloss und Kirche Uebigau (Deckenmalerei), Dresdner Straße 3 Herzberg Elster, Deckenmalerei und vieles vieles mehr in der Region,<br/>
              2012/13 Restaurierungsarbeiten am Staatstheater Cottbus, Schloss Branitz, Stadthaus Cottbus<br/>
              2012 1. Preis einer deutschlandweiten Ausschreibung für die Restaurierung des Staatstheaters an unser Team: Architekturbüro Berger und Fiedler Cottbus, Firma Schweizer Cottbus und Restauratorin Evelin Waldmann,
              Preisverleihung im Kunsthistorischen Museum in Wien<br/>
              2013 erhielt ich den Denkmalpreis der Stadt Cottbus<br/>
              2018 -2020 Arbeiten am Pergamonaltar in Berlin, Teilnahme am Abendkurs Aktmalerei<br/>
              Weitere Restaurierungsarbeiten in der Region, u.A. Frauenkirche Mühlberg, Oberkirche Cottbus, Kirche Bad Liebenwerda, Alter Bahnhof Drebkau, Schlosskirche Lauchhammer, Kavaliershaus Altdöbern, Kirche Uebigau Deckenmalerei<br/>
            </div>
            {/*<div className={"additionalInfos"}>Im Internet:<br/>
              <a href={"http://www.steffen-mertens.de"} className={'externalLink'}>www.steffen-mertens.de</a>
            </div>*/}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <PhotoSwipeGallery items={items} options={options} thumbnailContent={getThumbnailContent}/>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
